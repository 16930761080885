<template>
    <blocks v-if="content" :blocks="content.acf.blocks"></blocks>
</template>

<script>
    import Blocks from './../components/Blocks'

    export default {
        name: "Page",
        components: { Blocks },
        computed: {
            content() {
                const content = this.$store.getters.page(this.$route.params.slug)
                if(content && content.title) {
                  document.title = content.title.rendered + ' - Cyfrowy Dialog'
                }
                return content;
            }
        },
        mounted() {
            this.$store.dispatch('getPage', this.$route.params.slug)
        }
    }
</script>

<style scoped>

</style>